import { useAuthentication } from '@design-system/components/authentication/hooks/useAuthentication'
import { Page } from '@utils/urls'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { useMe } from '@api/auth/hooks/useMe'
import { saveBuildingsInLocalStorage } from '@api/buildings/buildingsHelpers'
import useGetAllBuildings from '@api/buildings/hooks/useGetAllBuildings'
import { datadogRum } from '@datadog/browser-rum'
import PageLoading from '@design-system/components/PageLoading/PageLoading'

const MAX_RETRY = 3
const BASE_TIMEOUT = 1000

export default function IndexPage() {
    useAuthentication()
    const router = useRouter()

    const { me, isMeLoading } = useMe(true, true)

    const { buildings, isLoadingBuildings, isRefetchingBuildings, refetchBuildings } = useGetAllBuildings()

    const [retryCount, setRetryCount] = useState<number>(0)

    useEffect(() => {
        if (isMeLoading) return

        if (!me) window.location.href = window.location.origin + Page.LOGIN

        if (isLoadingBuildings || isRefetchingBuildings) return

        const firstBuilding = buildings && buildings[0]
        const userUuid = me?.uuid || ''

        if (firstBuilding) {
            saveBuildingsInLocalStorage({
                userUuid,
                buildingUuid: firstBuilding.uuid,
                addressLabel: firstBuilding.address?.data?.addressString || '',
            })

            void router.push(Page.DASHBOARD({ buildingUuid: firstBuilding.uuid }))
            return
        } else {
            // eslint-disable-next-line no-console
            console.error('Something went wrong with fetching a building for user')
            const error = new Error(`Something went wrong with fetching a building for user: ${userUuid}`)
            datadogRum.addError(error, { buildings })

            setTimeout(() => {
                if (retryCount <= MAX_RETRY) {
                    setRetryCount(retryCount + 1)
                    void refetchBuildings()
                } else {
                    // TODO [UAQ-726] https://linear.app/enternow/issue/UAQ-726/error-page-for-lack-of-building
                    void router.push(Page.PROFILE)
                    return
                }
            }, BASE_TIMEOUT * retryCount)
        }
    }, [isLoadingBuildings, buildings, router, isMeLoading, me, refetchBuildings, isRefetchingBuildings, retryCount])

    return <PageLoading />
}
