import { dataDog } from '@design-system/utils/dataDog'
dataDog('customer-portal')

import 'react-toastify/dist/ReactToastify.css'

import { createEnterApi } from '@api/createApi'
import { createQueryClient } from '@design-system/api/apiFactory'
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'

import { UserStatusContextProvider } from '@common/context/userStatusContext'
import { EnterApiProvider } from '@design-system/api/apiContext'
import { useAuthentication } from '@design-system/components/authentication/hooks/useAuthentication'
import { SelectRoot } from '@design-system/components/forms/select'
import { ModalRoot } from '@design-system/components/modal'
import { ToastClient } from '@design-system/components/toast'
import { ScreenSizeProvider } from '@design-system/context/screenSizeContext'
import {
    AUTH_USER_UUID,
    EnterAuthConfigProvider,
    featureContext,
    USER_CUSTOM_TOKEN,
} from '@design-system/utils/authentication'
import { GoogleTagManagerScript } from '@design-system/utils/googleTagManager'
import { EnterPageProps } from '@design-system/utils/next'
import { Global } from '@emotion/react'
import { FirebaseCustomAuthentication } from '@modules/FirebaseCustomAuthentication'
import { globalStyles } from '@styles/global'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FEATURES, SONIC_AUTH_CONFIG } from '@utils/authentication'
import { NextPageContext } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import * as process from 'process'
import { useEffect, useState } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

App.getInitialProps = async (
    ctx: {
        router: {
            query: {
                [USER_CUSTOM_TOKEN]: string
                [AUTH_USER_UUID]: string
            }
        }
    } & NextPageContext
) => {
    const userCustomToken = ctx.router?.query?.[USER_CUSTOM_TOKEN] || ''
    const authUserUUID = ctx.router?.query?.[AUTH_USER_UUID] || ''
    return {
        userCustomToken,
        authUserUUID,
    }
}

export default function App({
    Component,
    pageProps,
    userCustomToken,
    authUserUUID,
}: { adminToken: string; userCustomToken: string; authUserUUID: string } & AppProps<EnterPageProps>) {
    const { refreshToken } = useAuthentication()

    useEffect(() => {
        const refreshTokenInterval = setInterval(
            async () => {
                await refreshToken()
            },
            15 * 60 * 1000 // refresh token every 15 min
        )

        return () => clearInterval(refreshTokenInterval)
    }, [])

    const [enterApi] = useState(() => createEnterApi())
    const [queryClient] = useState(() => createQueryClient())
    useAuthentication()

    // @ts-ignore
    const getLayout = Component.getLayout || ((page) => page)

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                <link rel="manifest" href="/manifest.webmanifest.json" />
                <link rel="icon" href="/favicon.ico" sizes="any" />
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <title>Enter App</title>
            </Head>
            {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
                <GoogleTagManagerScript gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string} />
            )}
            <FirebaseCustomAuthentication userCustomToken={userCustomToken} authUserUUID={authUserUUID}>
                <EnterApiProvider enterApi={enterApi}>
                    <EnterAuthConfigProvider config={SONIC_AUTH_CONFIG}>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools initialIsOpen={false} />
                            <HydrationBoundary state={pageProps?.dehydratedState}>
                                <SkeletonTheme baseColor={'#E1E1E4'}>
                                    <Global styles={globalStyles} />
                                    <featureContext.Provider value={{ ...FEATURES }}>
                                        <ScreenSizeProvider>
                                            <UserStatusContextProvider>
                                                <ToastClient />
                                                {getLayout(<Component {...pageProps} />)}
                                            </UserStatusContextProvider>
                                        </ScreenSizeProvider>
                                    </featureContext.Provider>
                                    <ModalRoot />
                                    <SelectRoot />
                                </SkeletonTheme>
                            </HydrationBoundary>
                        </QueryClientProvider>
                    </EnterAuthConfigProvider>
                </EnterApiProvider>
            </FirebaseCustomAuthentication>
            {/*temp solution for typeform embed*/}
            <div id="typeform-embed-blog" style={{ display: 'none', opacity: 0 }} />
        </>
    )
}
