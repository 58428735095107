import React, { useId } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { OPACITY_TRANSITION } from '../styles/helpers'

export enum IllustrationName {
    FlatRoofHouse = 'flat-roof-house',
    GableRoofHouse = 'gable-roof-house',
    HipRoofHouse = 'hip-roof-house',
    ShedRoofHouse = 'shed-roof-house',
    IsfpCalculator = 'isfp-calculator',
    EnergyConsulation = 'energy-consulation',
    RenovationMeasuresFinancing = 'renovation-measures-financing',
    RenovationMeasuresRenovationSchedule = 'renovation-measures-renovation-schedule',
    RenovationMeasuresSubsidyService = 'renovation-measures-subsidy-service',
    CheckSquare = 'check-square',
    DoneOnHouseDetails = 'done-on-house-details',
    ErrorOnHouseDetails = 'error-on-house-details',
    QuestionOnHouseDetails = 'question-on-house-details',
    SavingPotentialOnHouseDetails = 'saving-potential-on-house-details',
    VerifyHouseDetails = 'verify-house-details',
    Gift = 'gift',
    Arrow = 'arrow',
    FloorPlate = 'floor-plate',
    HeatPump = 'heat-pump',
    InsulateBasementCeiling = 'insulate-basement-ceiling',
    InsulateBasementWall = 'insulate-basement-wall',
    InsulateCeiling = 'insulate-ceiling',
    InsulateOutsideWall = 'insulate-outside-wall',
    InsulateRoof = 'insulate-roof',
    ReplaceFrontDoor = 'replace-front-door',
    ReplaceRoofWindows = 'replace-roof-windows',
    ReplaceWindows = 'replace-windows',
    WinterGarden = 'winter-garden',

    EnergyRatingAPlus = 'energy-rating-a+',
    EnergyRatingA = 'energy-rating-a',
    EnergyRatingB = 'energy-rating-b',
    EnergyRatingC = 'energy-rating-c',
    EnergyRatingD = 'energy-rating-d',
    EnergyRatingE = 'energy-rating-e',
    EnergyRatingF = 'energy-rating-f',
    EnergyRatingG = 'energy-rating-g',
    EnergyRatingH = 'energy-rating-h',

    EmissionCloud = 'cloud-01',
    EmissionCloudFuture = 'cloud-01-future',
    ExteriorWalls = 'exterior-walls',
    ExteriorWallsFuture = 'exterior-walls-future',
    Fire = 'fire',
    FireFuture = 'fire-future',
    Fire03 = 'fire-03',

    CircleCheckmark = 'circle-checkmark',
    CircleMinus = 'minus-circle',
    CalendarCheck01 = 'calendar-check-01',

    ArrowRight100 = 'arrow100',
    ArrowRight200 = 'arrow200',
    ArrowRight300 = 'arrow300',
    ArrowRight400 = 'arrow400',
    ArrowRight500 = 'arrow500',
    ArrowRight600 = 'arrow600',
    BarGraph = 'bar-graph',
    DirectionUpwards = 'direction-upwards',
    DirectionUpwards02 = 'direction-upwards-02',
    Communication = 'communication',
    HomeCircleInCenter = 'home-circle-in-center',
    SavingsPercentage = 'savings-percentage',
    ScalePencil = 'scale-pencil',
    SolarPanels = 'solar-panels',
    EnterLogoWhite = 'enter-logo-white-RGB',

    Mic = 'mic',
    DenaLogoNeu = 'dena-logo-neu',
    EnergyEfficientExpertLogo = 'energieeffizienzexperten-logo',

    Certificate01 = 'certificate-01',
    GraduationHat02 = 'graduation-hat-02',
    Mail01 = 'mail-01',
    EnvironmentPlug = 'environment-plug',

    HomeWithDoor = 'home-with-door',
    LikeTag = 'like-tag',
    PercentageSquare = 'percentage-square',

    WarmSun = 'warm-sun',
    ColdSun = 'cold-sun',
    ColdSun02 = 'cold-sun-02',
    NotesWithPencil = 'notes-with-pencil',
    PlantHand = 'plant-hand',

    Candle02 = 'candle-02',
    Fan01 = 'fan-01',
    Flash01 = 'flash-01',
    HeatStorage = 'heat-storage',
    Setting02 = 'setting-02',
    Share01 = 'share-01',
    Home02 = 'home-02',

    BioMassHeating = 'bio-mass-heating',
    Burning = 'burning',
    DistrictHeatingPipes = 'district-heating-pipes',
    HeatPowerPlantCombo = 'heat-power-plant-combo',
    HeatStorage02 = 'heat-storage-02',
    Oil01 = 'oil-01',
    Radiator = 'radiator',
    UnderfloorHeating01 = 'underfloor-heating-01',
    UnderfloorHeating02 = 'underfloor-heating-02',
    Ventilation = 'ventilation',
    Wind01 = 'wind-01',

    BioMass02 = 'bio-mass-02',
    HeatPump03 = 'heat-pump-03',
    Solar02 = 'solar-02',
    SolarPanel02 = 'solar-panel-02',

    EnterIcon01 = 'enter-icon-01',
    Din01 = 'din-01',
    Diamond01 = 'diamond-01',
    Walls01 = 'walls-01',
    CircleX01 = 'circle-x-01',
    CallingPhoneSign = 'calling-phone-sign',

    CoinsHand01 = 'coins-hand-01',
    CoinsStacked01 = 'coins-stacked-01',
    CreditCard02 = 'credit-card-02',
    Sale03 = 'sale-03',

    CoinsStacked02 = 'coins-stacked-02',
    SavingsPercentage02 = 'savings-percentage-02',
    CircleInfo01 = 'circle-info-01',
    Coins02 = 'coins-02',
    Plus01 = 'plus-01',

    SkipForward = 'skip-forward',
    BulletListing = 'bullet-listing',
    IosAppStore = 'ios-app-store',
    AndroidAppStore = 'android-app-store',
    GoogleReview = 'google-review',
    TrustPilotReview = 'trustpilot-review',
}

export type EnterIllustrationProps<T extends IllustrationName = IllustrationName> = {
    name: T
    styles?: SerializedStyles
    minHeight?: number
    minWidth?: number
    height?: number
    width?: number
    testId?: string
}

const getContainerStylesBySize = <T extends IllustrationName = IllustrationName>({
    minHeight,
    minWidth,
    height,
    width,
}: Pick<EnterIllustrationProps<T>, 'minHeight' | 'minWidth' | 'height' | 'width'>) => css`
    height: ${height ? `${height}px` : 'unset'};
    width: ${width ? `${width}px` : 'unset'};
    min-height: ${minHeight ?? 0}px;
    min-width: ${minWidth ?? 0}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: 100%;
    }
`

/**
 * @TODO In order to prepare a new illustration for use in this component, you need to:
 * 1. Add the illustration to the `packages/lib/enter-icons/illustrations` folder.
 * 2. Add the illustration name to the `IllustrationName` enum.
 */
export const EnterIllustration = ({
    name,
    styles,
    minHeight,
    minWidth,
    width,
    height,
    testId = '',
}: EnterIllustrationProps) => {
    const componentId = useId()

    const [loaded, setLoaded] = useState(false)

    const { Icon, error, isLoaded } = useDynamicSvgImport(name as IllustrationName)

    useEffect(() => {
        if (!isLoaded || typeof document === 'undefined') return

        const svg = document.getElementById(`${name}-${componentId}`) as SVGSVGElement | null

        if (!svg) return

        svg.setAttribute('viewBox', `0 0 ${svg.width.baseVal.value} ${svg.height.baseVal.value}`)

        setLoaded(true)
    }, [componentId, isLoaded, name, Icon])

    if (error) return null

    return (
        <motion.div
            css={css`
                ${getContainerStylesBySize({ minHeight, minWidth, width, height })}

                // Hide when not loaded, because the viewbox is set in a useEffect, therefore the viewbox is not set on the first render.
                visibility: ${loaded ? 'visible' : 'hidden'};

                ${styles};
            `}
            {...OPACITY_TRANSITION}
            data-testid={testId}
        >
            {Icon && <Icon id={`${name}-${componentId}`} />}
        </motion.div>
    )
}

/**
 *
 * @param illustrationName
 * @description This hook dynamically imports a svg icon by its file name.
 *
 * @see https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
 */
export const useDynamicSvgImport = (illustrationName: IllustrationName) => {
    const importedIconRef = useRef<React.FC<React.SVGProps<SVGElement>>>()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [error, setError] = useState<unknown>()

    const asyncImportSvgIcon = useCallback(async () => {
        setIsLoaded(false)

        try {
            // IMPORTANT: The path is not type safe. If the path changes, it needs to be updated manually.
            // This requires @svgr/webpack.
            importedIconRef.current = (await import(`!!@svgr/webpack!./illustrations/${illustrationName}.svg`)).default
        } catch (err) {
            setError(err)
        } finally {
            setIsLoaded(true)
        }
    }, [illustrationName])

    useEffect(() => {
        if (importedIconRef.current || isLoaded) return

        asyncImportSvgIcon()
    }, [asyncImportSvgIcon, isLoaded])

    return useMemo(() => ({ error, isLoaded, Icon: importedIconRef.current }), [error, isLoaded])
}
